import React, { useState, useEffect } from "react";
import { IoIosUndo, IoIosSend } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";

import Modal from "../../../components/Modals";
import api from "../../../services/api";

const ModalClient = ({ actionModal, openModal, entity, client, handleSuccess, setLoadingChangeStatus }) => {
    const [loading, setLoading] = useState(false);
    const [descriptionError, setDescriptionError] = useState("");
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});

    const loadUsers = client => {
        let aux = [];

        if (client?.length && client?.length > 0) {
            client.map(data => {
                return (
                    aux.push(data.value)
                )
            });

            api.get(`/quote/client/users`, {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
                params: { clients: aux },
            }).then(res => {
                let options = res?.data.map(data => {
                    return {
                        value: data.email,
                        label: data.name,
                    };
                });

                setUserOptions(options);
            });
        }
    };

    useEffect(() => {
        loadUsers(client);
    }, [client]);

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                        Liberar cotação {entity?.name} para análise do cliente
                    </h5>
                </div>
            }
        >
            <div className="w-full">
                <h6 className="mt-4">Selecione o usuário que irá receber o e-mail:</h6>
                <Select
                    name="user"
                    value={selectedUser}
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    placeholder=""
                    onChange={arr => {
                        setSelectedUser(arr);
                    }}
                    options={userOptions}
                />
            </div>

            <button
                className="mb-4 ml-2 bg-C4 text-white py-2 px-5 rounded-md float-left mt-10 flex items-center"
                onClick={() => {
                    actionModal();
                    setLoadingChangeStatus(false);
                }}
            >
                <IoIosUndo size={"1em"} />
                <span className="text-lg ml-1">Voltar</span>
            </button>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10 flex items-center"
                onClick={() => {
                    if (selectedUser?.length === 0) {
                        setDescriptionError("O motivo é obrigatório");
                        return;
                    }

                    setLoading(true);

                    api.post(
                        "quote/" + entity?.identifier + "/change-status",
                        {
                            status: "review_as_client",
                            name: selectedUser?.label,
                            email: selectedUser?.value,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    )
                        .then(response => {
                            setLoadingChangeStatus(false);
                            window.location.href = "/cotacao";
                        })
                        .catch(error => {
                            setLoadingChangeStatus(false);
                        });
                }}
            >
                <IoIosSend size={"1em"} />
                <span className="text-lg ml-1">Enviar {loading && <ClipLoader size="13" color="white" />}</span>
            </button>
        </Modal>
    );
};
export default ModalClient;
